<template>
  <div class="app-container">
    <el-form ref="params" :model="params" :inline="true">
      <el-form-item>
        <el-input
          v-model="params.etpName"
          class="input"
          size="mini"
          style="width: 150px"
          placeholder="投标企业"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="params.projectName"
          class="input"
          size="mini"
          style="width: 150px"
          placeholder="项目名称"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="params.projectCode"
          class="input"
          size="mini"
          style="width: 150px"
          placeholder="项目编码"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="params.guaranteeId"
          class="input"
          size="mini"
          style="width: 150px"
          placeholder="保函唯一标识"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="params.isDownload"
          clearable
          size="mini"
          style="width: 150px"
          placeholder="是否处理"
          @clear="getList"
        >
          <el-option
            v-for="item in isDownloadoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="params.status"
          clearable
          size="mini"
          style="width: 150px"
          placeholder="订单状态"
          @clear="getList"
        >
          <el-option
            v-for="item in guaranteeStatusOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="this.params.status === 4">
        <el-select
          v-model="params.states"
          clearable
          size="mini"
          style="width: 150px"
          placeholder="退款状态"
          @clear="getList"
        >
          <el-option
            v-for="item in refundStatesOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="
          this.params.status === '01' ||
          this.params.status === '04' ||
          this.params.status === '05'
        "
      >
        <maindata
          v-model="params.rejectReasonType"
          data-type="singleSelect"
          data-key="guarantee-reject-type"
          data-title="拒保理由"
          style="width: 150px"
        >
        </maindata>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="params.guaranteeType"
          clearable
          size="mini"
          style="width: 150px"
          placeholder="保函性质"
          @clear="getList"
        >
          <el-option
            v-for="item in guaranteeTypeOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="guaranteeCategory"
          clearable
          size="mini"
          style="width: 150px"
          placeholder="保函分类"
          @clear="getList"
        >
          <el-option
            v-for="item in guaranteeCategoryOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <maindata
          v-model="params.returnGuaranteeOrg"
          data-type="singleSelect"
          data-key="returnGuaranteeOrg"
          data-title="出函机构"
          style="width: 150px"
        >
        </maindata>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="params.guaranteeSource"
          clearable
          size="mini"
          style="width: 150px"
          placeholder="保函来源"
          @clear="getList"
        >
          <el-option
            v-for="item in enterpriseSystemOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="params.start"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="mini"
          type="datetime"
          placeholder="申请时间开始"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="params.end"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="mini"
          type="datetime"
          placeholder="申请时间结束"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="getList"
          >查询</el-button
        >
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-refresh-left"
          @click="deleteAddForm"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <div style="margin-top: 20px">
      <el-button
        size="mini"
        type="primary"
        @click="bulkDownload"
        v-if="menuPriPath.indexOf('list-download') >= 0"
        >批量下载</el-button
      >
      <el-dropdown @command="goApplyFor" style="margin-left: 10px" v-if=" menuPriPath.indexOf('apply-guarantee') >= 0">
        <el-button type="primary" size="mini">
          申请保函<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="01">投标保函</el-dropdown-item> -->
          <el-dropdown-item command="02">履约保函</el-dropdown-item>
          <el-dropdown-item command="04">预付款保函</el-dropdown-item>
          <el-dropdown-item command="03">质量保证保函</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table
      ref="multipleTable"
      size="mini"
      fit
      :data="tableData"
      style="width: 100%; margin-top: 10px"
      border
      :height="tableDataHeight"
      v-loading="tableLoading"
      :row-class-name="tableRowClassName"
      :row-key="getRowKey"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        :reserve-selection="true"
        width="55"
        header-align="center"
        align="center"
        fixed=""
      />
      <el-table-column
        type="index"
        label="序号"
        width="50"
        :index="indexMethod"
        header-align="center"
        align="center"
        fixed=""
      />
      <el-table-column label="操作" width="300" header-align="center" align="left" fixed="">
        <template slot-scope="scope">
          <el-button
            class="operatorBtn"
            size="mini"
            v-if="
              scope.row.guaranteeCategory !== '01' &&scope.row.guaranteeSource!=='10001' && 
              scope.row.status === '99' &&
              menuPriPath.indexOf('edit') >= 0
            "
            type="text"
            @click="toEdit(scope.row)"
            >去编辑</el-button
          >
          <el-button
            class="operatorBtn"
            v-if="menuPriPath.indexOf('detail') >= 0"
            size="mini"
            type="text"
            @click="detailClick(scope.row)"
            >查看详情</el-button
          >
          <el-button
            class="operatorBtn"
            size="mini"
            v-if="
              scope.row.status === '99' && menuPriPath.indexOf('confirm') >= 0
            "
            type="text"
            @click="confirmBhSubmit(scope.row)"
            >审核通过</el-button
          >
          <el-button
            class="operatorBtn"
            size="mini"
            v-if="
              scope.row.status === '00' && menuPriPath.indexOf('modifyPayMoney') >= 0
            "
            type="text"
            @click="modifyPayMoneySubmit(scope.row)"
            >修改价格</el-button
          >
          <el-button
            class="operatorBtn"
            size="mini"
            v-if="
              (scope.row.status === '02' ||
                scope.row.status === '03' ||
                scope.row.status === '06') &&
              menuPriPath.indexOf('upload') >= 0
            "
            type="text"
            @click="uploadGuara(scope.row)"
            >上传保函</el-button
          >
          <el-button
            class="operatorBtn"
            v-if="
              scope.row.returnGuarantee != '' &&
              menuPriPath.indexOf('view-guarantee') >= 0
            "
            size="mini"
            type="text"
            @click="getGuara(scope.row)"
            >查看保函</el-button
          >
          <el-button
            class="operatorBtn"
            v-if="menuPriPath.indexOf('view-operator') >= 0"
            size="mini"
            type="text"
            @click="getGuaraOperator(scope.row)"
            >查看经办人</el-button
          >
          <el-button
            class="operatorBtn"
            v-if="
              (scope.row.status === '00' || scope.row.status === '02'|| scope.row.status === '99') &&
              menuPriPath.indexOf('reject') >= 0
            "
            size="mini"
            type="text"
            @click="reject(scope.row.guid)"
            >拒保</el-button
          >
           <el-button
            class="operatorBtn"
            v-if="
              (scope.row.status === '01' || scope.row.status === '04'|| scope.row.status === '05')
            "
            size="mini"
            type="text"
            @click="reject_view(scope.row)"
            >查看拒保原因</el-button
          >
          <el-button
            class="operatorBtn"
            type="text"
            v-if="
              scope.row.guaranteeCategory !== '01' && scope.row.isFixSignDocs
            "
            size="small"
            @click="signUploadBtn(scope.row)"
            >查看签约资料</el-button
          >
          <el-button
            class="operatorBtn"
            type="text"
            v-if="
              scope.row.guaranteeCategory !== '01' &&
              scope.row.isSubmitPayVoucher
            "
            size="small"
            @click="etplegaPayClick(scope.row)"
            >查看付款凭证</el-button
          >
          <el-button
            v-if="
              scope.row.guaranteeCategory !== '01' &&
              scope.row.isSubmitPayVoucher &&
              scope.row.status === '00'
            "
            class="operatorBtn"
            size="mini"
            type="text"
            @click="confirmReceiveMoneyBtn(scope.row)"
            >确认收款</el-button
          >
        </template>
      </el-table-column>
     <el-table-column
        prop="statusName"
        width="150px"
        label="订单状态"
        header-align="center" align="center"

      />
      <el-table-column
        width="100px"
        prop="isDownload"
        label="是否处理"
        header-align="center" align="center"

      >
        <template slot-scope="scope">
          <div v-if="scope.row.isDownload === 1">
            {{ scope.row.isDownloadName }}
          </div>
          <div
            style="width: 80px; background-color: #f35565; color: #ffffff"
            v-else
          >
            {{ scope.row.isDownloadName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        width="150px"
        prop="etpName"
        label="投标企业"
header-align="center" align="left"
      />
      <el-table-column
        width="150px"
        prop="projectName"
        label="项目名称"
header-align="center" align="left"
      />
      <el-table-column
        width="150px"
        prop="projectCode"
        label="项目编码"
        header-align="center" align="left"
      />
      <el-table-column
        prop="guaranteeBeneficiary"
        label="受益人"
header-align="center" align="left"
        width="150px"
      />
       <el-table-column
        prop="guaranteeMoney"
        label="担保金额(元)"
        header-align="center" align="left"
        width="100px"
      />
       <el-table-column
        width="200px"
        prop="guaranteeYxq"
        label="有效期"
        header-align="center" align="left"
      />
      <el-table-column
        width="150px"
        prop="payTime"
        label="支付时间"
        min-width="135"
        header-align="center" align="left"
      >
        <template slot-scope="scope">
          <div>
            {{ $parseTime(scope.row.payTime) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        width="150px"
        prop="payTime"
        label="申请时间"
        min-width="135"
        header-align="center" align="left"
      >
        <template slot-scope="scope">
          <div>{{ $parseTime(scope.row.created) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="guaranteePayMoney"
        label="支付金额(元)"
       header-align="center" align="left"
        width="100px"
      >
      <template slot-scope="scope">
            {{ scope.row.guaranteePayMoney || '--' }}
          </template>
      </el-table-column>
      <el-table-column
        prop="guaranteeTypeName"
        label="保函性质"
 header-align="center" align="left"
        width="100px"
      />
      <el-table-column
        prop="guaranteeCategoryName"
        label="保函分类"
header-align="center" align="left"
        width="100px"
      />
      <el-table-column
        prop="returnGuaranteeOrgName"
        label="出函机构"
header-align="center" align="left"
        width="100px"
      />
       <el-table-column
        prop="guaranteeSourceName"
        label="保函来源"
header-align="center" align="left"
      />
      <el-table-column
        prop="agentName"
        width="150px"
        label="代理商"
        header-align="center" align="left"
      />
       <el-table-column
        width="100px"
        prop="buildTypeName"
        label="下单方式"
        header-align="center" align="left"
      />
       <el-table-column
        width="150px"
        prop="guid"
        label="保函唯一标识"
        header-align="center" align="left"
      />
      <el-table-column
        prop="returnSuccGuaranteeNums"
        label="出函数量"
       header-align="center" align="left"
      />

    </el-table>
    <!-- 分页 -->
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="params.currentPage"
      :limit.sync="params.pageSize"
      @pagination="getList"
    />
    <!-- <preview ref="preview" /> -->
    <el-dialog
      title="拒保"
      :visible.sync="jbVisible"
      width="800px"
      append-to-body
      v-if="jbVisible"
    >
      <el-form ref="form1" :model="form1" :rules="rules1" label-width="120px">
        <el-row>
          <el-col>
            <el-form-item label="选择拒保原因" prop="rejectReasonType">
              <maindata
                v-model="form1.rejectReasonType"
                data-type="singleSelect"
                data-key="guarantee-reject-type"
              >
              </maindata>
              <el-input
                v-model="form1.rejectReason"
                placeholder="请输入其它原因"
                v-if="form1.rejectReasonType === '08'"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider></el-divider>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm1">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="拒保"
      :visible.sync="reject_view_dlg"
      width="800px"
      append-to-body
      v-if="reject_view_dlg"
    >
      <el-form ref="reject_view_form" :model="reject_view_form" label-width="120px">
        <el-row>
          <el-col>
            <el-form-item label="拒保原因" prop="rejectReasonType">
              <maindata
                v-model="reject_view_form.rejectReasonType"
                data-type="singleSelect"
                data-key="guarantee-reject-type"
              >
              </maindata>
              <el-input
                v-model="reject_view_form.rejectReason"
                placeholder="请输入其它原因"
                v-if="reject_view_form.rejectReasonType === '08'"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- <el-divider></el-divider>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm1">确 定</el-button>
      </div> -->
    </el-dialog>
    <el-dialog
      title="上传保函"
      :visible.sync="uploadGuaranteeVisible"
      width="800px"
      append-to-body
      v-if="uploadGuaranteeVisible"
    >
      
      <el-form ref="uploadForm" :model="uploadForm" :rules="uploadForm_rule" label-width="120px">
        <el-row>
          <el-col>
            <el-form-item label="保函文件" prop="returnGuarantee">
             <upLoadImg
              v-model="uploadForm.returnGuarantee"
              :limit="1"
              accept=".pdf,.jpeg,.jpg,.png"
              @syncFileIds="setReturnGuarantee"
            >
            </upLoadImg>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="快递公司名称" prop="guaranteePostType">
              <el-input
               placeholder="请输入快递公司名称"
                v-model="uploadForm.guaranteePostType"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="快递单号" prop="guaranteePostCode">
                          <el-input
                           placeholder="请输入快递单号"
                v-model="uploadForm.guaranteePostCode"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider></el-divider>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitUploadForm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="审核保函"
      :visible.sync="confirmGuaranteeVisible"
      width="800px"
      append-to-body
      v-if="confirmGuaranteeVisible"
    >
      <el-form
        ref="formConfirm"
        :model="formConfirm"
        :rules="formConfirmRule"
        label-width="180px"
      >
        <el-row>
          <el-col>
            <el-form-item label="选择输入保函支付金额" prop="guaranteePayMoney">
              <el-input v-model="formConfirm.guaranteePayMoney" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider></el-divider>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitConfirmForm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="修改保函价格"
      :visible.sync="modifyPayMoneyGuaranteeVisible"
      width="800px"
      append-to-body
      v-if="modifyPayMoneyGuaranteeVisible"
    >
      <el-form
        ref="modifyPayMoney"
        :model="modifyPayMoney"
        :rules="modifyPayMoneyRule"
        label-width="180px"
      >
        <el-row>
          <el-col>
            <el-form-item label="选择输入保函支付金额" prop="guaranteePayMoney">
              <el-input v-model="modifyPayMoney.guaranteePayMoney" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider></el-divider>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitModifyPayMoneyForm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="uploadPreviewVisible" append-to-body>
      <img width="90%" :src="uploadImageUrl" alt="" />
    </el-dialog>
    <el-dialog
      title="企业信息"
      :visible.sync="viewCompanyVisible"
      width="800px"
      append-to-body
      v-if="viewCompanyVisible"
    >
      <company v-model="id" :fileName="fileName"></company>
    </el-dialog>
    <el-dialog
      title="经办人信息"
      :visible.sync="dialogOperatorVisible"
      width="800px"
      append-to-body
      v-if="dialogOperatorVisible"
    >
      <div class="complete">
        <el-table
          size="mini"
          fit
          :data="tableDataOperator"
          style="width: 100%; margin-top: 10px"
          border
        >
          <el-table-column label="名字" align="center" prop="operator" />
          <el-table-column label="手机号" align="center" prop="operatorPhone" />
        </el-table>
      </div>
    </el-dialog>
    <el-dialog
      v-if="dialogVisibleEtplega"
      title=""
      :visible.sync="dialogVisibleEtplega"
      width="750px"
      :close-on-click-modal="false"
    >
      <payMsg
        @childEditBtn="childEditBtn"
        :guaranteeId="guaranteeId"
        :guaranteePayMoney="guaranteePayMoney"
        :view="true"
      ></payMsg>
    </el-dialog>
  </div>
</template>
<script>
import { getMenuPriByMenuId } from '@/api/menu/index'
import maindata from '@/components/MainData/index.vue'
import company from './company.vue'
import upLoadImg from '@/components/ImageUpload/indexNew.vue'
import { detailUpload, downloadFile } from '@/api/store/file'
import { confirmReceiveMoney } from '@//api/system/applyFor'
import {
  listApplication
} from '@/api/system/application'
import payMsg from '@/components/payMsg.vue'
// 0:已提交,1:已支付,2:待开保函,3:已开保函,4:已驳回
// 增 删 改 查 Add, delete, modify, check
import {
  list,
  listDownload,
  saveReturnGuarantee,
  getOperator,
  reject,
  getGuaranteeType,
  getGuaranteeCategory,
  getGuaranteeStatus,
  getRefundOrderStatus,
  confirmBh,
  modifyGuaranteePayMoney
} from '@/api/guarantee/guarantee'
import { stringify } from 'qs'
// import preview from '../preview/index'
// import Utils from '../../../../utils/utils'
export default {
  name: 'List',
  components: {
    maindata,
    upLoadImg,
    company,
    payMsg
  },
  data() {
    return {
      dialogVisibleEtplega: false,
      reject_view_dlg:false,
      reject_view_form:{},
      guaranteeId: '',
      guaranteePayMoney: '',
      tableDataHeight:0,
      menuPriPath: [], //菜单角色权限
      jbVisible: false,
      fileName: '压缩包',
      jbOptions: [], //拒保理由
      refundStatesOptions: [],
      guaranteeStatusOptions: [], //订单状态
      guaranteeTypeOptions: [], //保函类型
      guaranteeCategory:undefined,
      guaranteeCategoryOptions: [
         {
          code: '02',
          description: '履约保函'
        },
         {
          code: '03',
          description: '质量保函'
        },
         {
          code: '04',
          description: '预付款保函'
        },
         {
          code: '05',
          description: '年度保函'
        }

      ], //保函分类
      uploadForm: {
        guaranteeId: undefined,
        guaranteePostType:undefined,
        guaranteePostCode:undefined,
        returnGuarantee:undefined
      }, //上传保函表单
      modifyPayMoney: {
        guaranteeId: undefined,
        guaranteePayMoney: undefined
      }, //修改保函价格表单
      formConfirm: {
        guaranteeId: undefined,
        guaranteePayMoney: undefined
      }, //审核保函表单
      uploadGuaranteeVisible: false,
      uploadPreviewVisible: false, //预览保函弹窗
      uploadImageUrl: '', //保函文件
      viewCompanyVisible: false, //查看企业信息文件
      dialogOperatorVisible: false, //操作人窗口
      confirmGuaranteeVisible: false, //审核保函
      modifyPayMoneyGuaranteeVisible: false,//修改保函价格
      tableDataOperator: [],
      isDownloadoptions: [
        {
          value: 0,
          label: '未处理'
        },
        {
          value: 1,
          label: '已处理'
        }
      ],
      multipleSelection: [],
      ids: [],
      tableLoading: false,
      id: '',
      name: '',
      ziptype: '',
      operator: {},
      params: {
        etpUserId: undefined,
        agentId: undefined,
        etpName: undefined,
        projectName: undefined,
        projectCode:undefined,
        guaranteeId: undefined,
        isDownload: undefined,
        status: undefined,
        guaranteeType: undefined,
        returnGuaranteeOrg: undefined,
        guaranteeCategory: undefined,
        beginCreated: undefined,
        guaranteeSource:undefined,
        endCreated: undefined,
        rejectReasonType: undefined,
        currentPage: 1,
        pageSize: 10
      },
      total: 0,
      windowHeight: 0,
      // 假数据
      tableData: [],
      enterpriseSystemOptions:[],
      rejectId: '',
      form1: {},
      rules1: {
        rejectReasonType: [
          { required: true, message: '拒保原因类型不能为空', trigger: 'blur' }
        ]
      },
      uploadForm_rule: {
      },
      formConfirmRule: {
        guaranteePayMoney: [
          { required: true, message: '保函支付金额不能为空', trigger: 'blur' }
        ]
      },
      modifyPayMoneyRule:{
         guaranteePayMoney: [
          { required: true, message: '保函支付金额不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // 挂载，让页面更新就获取数据
    // this.getWindowHeight();
    // this.initRefundOrderStates()
    // this.functiona()
    this.updateWindowHeight()
  },
  mounted() {
    
    const path = this.$route.query
    const agentId = path.agentId
    const etpUserId = path.userId
    this.params.agentId = agentId
    this.params.etpUserId = etpUserId
    this.getRolePriList()
    this.initGuaranteeStatus()
    // this.initGuaranteeCategory()
    this.initGuaranteeType()
    this.getList()
    this.getEtpSystem()
    
  },
  methods: {
    updateWindowHeight() {
      this.tableDataHeight = window.innerHeight-300
      console.log(this.tableDataHeight)
    },
    confirmReceiveMoneyBtn(v) {
      // 确认收款
      this.$alert('是否确认收款？', '确认收款', {
        confirmButtonText: '确定',
        callback: (action) => {
          confirmReceiveMoney({ guaranteeId: v.guid }).then((res) => {
            if (res.data.success) {
              this.$message.success(res.data.message)
              this.getList()
            } else {
              this.$msgError(res.data.message)
            }
          })
        }
      })
    },
    childEditBtn() {
      this.dialogVisibleEtplega = false
      this.getList()
    },
    signUploadBtn(row) {
      let url = '/applyForMore/signUpload?view=true&guaranteeId=' + row.guid
      window.open(url, '_blank')
    },
    etplegaPayClick(row) {
      // 提交付款凭证
      this.dialogVisibleEtplega = true
      this.guaranteeId = row.guid
      this.guaranteePayMoney = row.guaranteePayMoney
    },
    detailClick(row) {
      // 查看详情
      if (row.guaranteeCategory == '01') {
        if (row.status == '00') {
          // 未支付
          let url = '/system/applyFor/payment?type=2&guaranteeId=' + row.guid
          window.open(url, '_blank')
        } else if (row.status == '02') {
          // 已支付
          let url = '/system/applyFor/payment?type=3&guaranteeId=' + row.guid
          window.open(url, '_blank')
        } else if (row.status == '03' || row.status == '06') {
          // 已支付已开函
          let url = '/system/applyFor/payment?type=4&guaranteeId=' + row.guid
          window.open(url, '_blank')
        } else if (row.status == '04' || row.status == '05') {
          // 已拒绝
          let url =
            '/system/applyFor/payment?type=2&isPaybtn=2&guaranteeId=' + row.guid
          window.open(url, '_blank')
        }
      } else {
        if (row.status == '99') {
          // 待审核
          let url = '/system/applyForMore/Detail?guaranteeId=' + row.guid
          window.open(url, '_blank')
        } else if (row.status == '00') {
          // 未支付
          let url =
            '/system/applyForMore/Detail?isPage=2&guaranteeId=' +
            row.guid +
            '&isFixSignDocs' +
            row.isFixSignDocs
          window.open(url, '_blank')
        } else if (row.status == '01') {
          // 已拒绝
          let url =
            '/system/applyForMore/Detail?isPage=1&guaranteeId=' +
            row.guid +
            '&isFixSignDocs' +
            row.isFixSignDocs
          window.open(url, '_blank')
        } else if (row.status == '02') {
          // 已支付
          let url =
            '/system/applyForMore/Detail?isPage=3&guaranteeId=' +
            row.guid +
            '&isFixSignDocs' +
            row.isFixSignDocs
          window.open(url, '_blank')
        } else if (row.status == '03') {
          // 已开函未开票
          let url = '/system/applyForMore/Detail?isPage=4&guaranteeId=' + row.guid
          window.open(url, '_blank')
        } else if (row.status == '04') {
          // 已拒保未退款
          let url =
            '/system/applyForMore/Detail?isPage=1&guaranteeId=' +
            row.guid +
            '&isFixSignDocs' +
            row.isFixSignDocs
          window.open(url, '_blank')
        } else if (row.status == '05') {
          // 已拒保已退款
          let url =
            '/system/applyForMore/Detail?isPage=1&guaranteeId=' +
            row.guid +
            '&isFixSignDocs' +
            row.isFixSignDocs
          window.open(url, '_blank')
        } else if (row.status == '06') {
          // 已开函已开票
          let url = '/system/applyForMore/Detail?isPage=4&guaranteeId=' + row.guid
          window.open(url, '_blank')
        }
      }
    },
    goApplyFor(command) {
      window.open(
        '/system/applyFor/index?guaranteeCategory=' + command,
        '_blank'
      )
    },
    //去支付
    toPay(row) {
      let url = '/system/applyFor/payment?type=2&guaranteeId=' + row.guid
      window.open(url, '_blank')
    },
    //去编辑
    toEdit(row) {
      if (row.guaranteeCategory == '01') {
        let url = '/system/applyFor/begin?guaranteeId=' + row.guid
        window.open(url, '_blank')
      } else {
        let url = '/system/applyForMore/begin?guaranteeId=' + row.guid
        window.open(url, '_blank')
      }
    },
    getRolePriList() {
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    initGuaranteeCategory() {
      getGuaranteeCategory().then((res) => {
        if (res.data.success) {
          this.guaranteeCategoryOptions = res.data.result
        }
      })
    },
    initGuaranteeType() {
      getGuaranteeType().then((res) => {
        if (res.data.success) {
          this.guaranteeTypeOptions = res.data.result
        }
      })
    },
    initGuaranteeStatus() {
      getGuaranteeStatus().then((res) => {
        if (res.data.success) {
          this.guaranteeStatusOptions = res.data.result
        }
      })
    },
    initRefundOrderStates() {
      getRefundOrderStatus().then((res) => {
        if (res.data.success) {
          this.refundStatesOptions = res.data.result
        }
      })
    },
    indexMethod(index) {
      return (this.params.currentPage - 1) * this.params.pageSize + index + 1
    },
    //上传保函
    setReturnGuarantee(val) {
      this.uploadForm.returnGuarantee = val
    },
    functiona() {
      console.log('进入企业详情列表调用顶部方法')
      //   Utils.$emit('demo', 'msg')
    },
    // 获取列表页请求
    getList() {
      if (this.tableLoading) return
      this.tableLoading = true
      if(!this.guaranteeCategory){
        this.params.guaranteeCategory='02,03,04,05';
      }else{
        this.params.guaranteeCategory=this.guaranteeCategory;
      }
      list(this.params).then((res) => {
        this.tableLoading = false
        if (res.data.success) {
          this.tableData = res.data.result.records
          this.total = res.data.result.total
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    // 监听pagesize
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.params.size = newSize
      this.getList()
    },
    // 页码值发生切换
    handleCurrentChange(newPage) {
      // console.log(newPage)
      // console.log('将分页保存到session中')
      // const page = newPage
      // sessionStorage.setItem('page', page)
      this.params.page = newPage
      this.getList()
    },
    // 清空
    deleteAddForm(formName) {
      this.params.guaranteeId = undefined
      this.params.guaranteeSource=undefined
      this.guaranteeCategory=undefined
      this.params.etpName = undefined
      this.params.projectName = undefined
      this.params.projectName = undefined
      this.params.projectCode = undefined
      this.params.guid = undefined
      this.params.isDownload = undefined
      this.params.status = undefined
      this.params.guaranteeType = undefined
      this.params.guaranteeCategory = undefined
      this.params.returnGuaranteeOrg = undefined
      this.params.beginCreated = undefined
      this.params.endCreated = undefined
      this.params.rejectReasonType = undefined
      this.params.currentPage = 1
      this.params.pageSize = 10
      this.getList()
    },
    tableRowClassName({ row }) {
      if (row.isDownload === 1) {
        return 'success-row'
      } else {
        return 'warning-row'
      }
    },
    getRowKey(row) {
      // console.log('标识的id')
      // console.log(row.id)
      return row.guid
    },
    // 获取选中的id
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.ids = []
      for (let i = 0; i <= this.multipleSelection.length - 1; i++) {
        this.ids.push(this.multipleSelection[i].guid)
      }
      console.log(val)
    },
    getDate() {
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      // 将月份和日期转换为两位数的格式
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      // 返回格式化后的日期字符串
      return year + '-' + month + '-' + day
    },
    submitUploadForm() {
      if (!this.uploadForm.guaranteeId || !this.uploadForm.returnGuarantee) {
        return this.$msgError('保函文件不能为空')
      }
      const data = {
        guaranteeId: this.uploadForm.guaranteeId,
        returnGuarantee: this.uploadForm.returnGuarantee,
        guaranteePostType:this.uploadForm.guaranteePostType,
        guaranteePostCode:this.uploadForm.guaranteePostCode
      }
      saveReturnGuarantee(data).then((res) => {
        if (res.data.success) {
          this.$msgSuccess('上传成功')
          this.uploadGuaranteeVisible = false
          this.getList()
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    bulkDownload() {
      if (this.ids.length === 0) {
        this.$message({
          message: '请选择数据',
          type: 'warning'
        })
      } else {
        const h = this.$createElement
        this.$msgbox({
          title: '消息',
          message: h('p', null, [
            h('span', null, '选中的企业数量为 '),
            h('i', { style: 'color: teal' }, this.ids.length),
            h('span', null, '，  是否继续下载')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              console.log('继续下载')
              const params = {
                id: this.ids
              }
              this.fileName =
                this.getDate() + '建行资料-(' + this.ids.length + ')笔'
              this.uploadZip()
              done()
              // getListName(params).then(res => {
              //   if (res.code === 200) {
              //     console.log(res)
              //     this.fileName = res.data
              //     this.uploadZip()
              //     done()
              //   } else {
              //     this.$message({
              //       message: res.message,
              //       type: 'warning'
              //     })
              //   }
              // })
            } else {
              done()
            }
          }
        }).catch((err) => {
          console.log(err)
          this.$message({
            message: '已取消下载',
            type: 'info'
          })
        })
      }
    },
    uploadZip() {
      const params = {
        id: this.ids
      }
      console.log(params)
      const loading = this.$loading({
        lock: true,
        text: '数据下载中……',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      listDownload(params).then((res) => {
        console.log('压缩包打印数据')
        // console.log(res)
        const content = res.data
        const blob = new Blob([content], { type: 'application/zip' })
        console.log(blob)
        const fileName = this.fileName
        // if ('download' in document.createElement('a')) { // 非IE下载
        console.log('非ie')
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
        this.$refs.multipleTable.clearSelection()
        loading.close()
        // } else { // IE10+下载
        //   console.log('ie下载')
        //   navigator.msSaveBlob(blob, fileName)
        // }
      })
    },
    getDetail(row) {
      console.log('企业信息')
      this.id = row.guid
      this.fileName = row.etpName
      this.viewCompanyVisible = true
      console.log(this.id)
    },
    uploadGuara(row) {
      console.log('上传保函')
      const obj=JSON.stringify(row)
      const rd=JSON.parse(obj)
      this.uploadForm.guaranteeId =rd.guid
      this.uploadForm.returnGuarantee = rd.returnGuarantee
      this.uploadForm.guaranteePostType=rd.guaranteePostType
      this.uploadForm.guaranteePostCode=rd.guaranteePostCode
      this.uploadGuaranteeVisible = true
    },
    // 预览保函
    getGuara(row) {
      if (!row.returnGuarantee) {
        return this.$msgError('不存在保函文件')
      }
      this.uploadImageUrl = ''
      let data = {
        guid: row.returnGuarantee
      }
      //获取文件类型
      this.loading = true
      detailUpload(data).then((res) => {
        this.loading = false
        if (res.data.success && res.data.result) {
          console.log(res.data.result)
          this.previewFile(row.returnGuarantee, res.data.result.fileType)
        } else {
          this.$msgError('文件无效')
        }
      })
    },
     //获取企业系统
    getEtpSystem(){
      this.etpSystemloading = true
      this.enterpriseSystemOptions=[]
      const data={
                currentPage: 1,
        pageSize: 100
      }
      listApplication(data).then((res) => {
        this.etpSystemloading=false
        if (res.data.success) {
          let appList= res.data.result.records
          appList.forEach(element => {
            let app={}
            app.code=element.appid
            app.description=element.name
            this.enterpriseSystemOptions.push(app)
          })
          console.log(this.enterpriseSystemOptions)
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    //查询经办
    getGuaraOperator(row) {
      let data = {
        guaranteeId: row.guid
      }
      this.loading = true
      this.tableDataOperator = []
      getOperator(data).then((res) => {
        this.loading = false
        if (res.data.success && res.data.result) {
          console.log(res.data.result)
          let vv = {}
          vv.operator = res.data.result.operator
          vv.operatorPhone = res.data.result.operatorPhone

          this.tableDataOperator.push(vv)
          this.dialogOperatorVisible = true
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    previewFile(id, fileType) {
      const data = {
        guid: id
      }
      this.loading = true
      downloadFile(data).then((res) => {
        //download
        this.loading = false
        if (fileType === '.pdf') {
          const url = window.URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/pdf'
            })
          )
          window.open(url, 'newWindow')
        } else {
          let url = window.URL.createObjectURL(new Blob([res.data]))
          this.uploadImageUrl = url
          this.uploadPreviewVisible = true
        }
      })
    },
    confirmBhSubmit(row) {
      this.confirmGuaranteeVisible = true
      this.formConfirm.guaranteeId = row.guid
      this.formConfirm.guaranteePayMoney = row.guaranteePayMoney
    },
    //修改保函价格
    modifyPayMoneySubmit(row){
      this.modifyPayMoneyGuaranteeVisible = true
      this.modifyPayMoney.guaranteeId = row.guid
      this.modifyPayMoney.guaranteePayMoney = row.guaranteePayMoney
    },
    //修改保函价格提交
    submitModifyPayMoneyForm(){
        this.$refs['modifyPayMoney'].validate((valid) => {
        if (valid) {
          const data = {
            guaranteeId: this.modifyPayMoney.guaranteeId,
            guaranteePayMoney: this.modifyPayMoney.guaranteePayMoney
          }
          modifyGuaranteePayMoney(data).then((res) => {
            if (res.data.success) {
              this.modifyPayMoneyGuaranteeVisible = false
              this.$message({
                message: '设置成功',
                type: 'success'
              })
              this.getList()
            } else {
              this.$message({
                message: res.data.message,
                type: 'warning'
              })
            }
          })
        }
      })
    },
    submitConfirmForm() {
      this.$refs['formConfirm'].validate((valid) => {
        if (valid) {
          const data = {
            guaranteeId: this.formConfirm.guaranteeId,
            guaranteePayMoney: this.formConfirm.guaranteePayMoney
          }
          confirmBh(data).then((res) => {
            if (res.data.success) {
              this.confirmGuaranteeVisible = false
              this.$message({
                message: '审核通过',
                type: 'success'
              })
              this.getList()
            } else {
              this.$message({
                message: res.data.message,
                type: 'warning'
              })
            }
          })
        }
      })
      //  if(!this.formConfirm.guaranteePayMoney){
      //   return this.$msgError('保函支付金额不能为空')
      //  }
    },
    //拒保
    submitForm1() {
      if (!this.form1.rejectReasonType) {
        return this.$message({
          message: '拒保原因不能为空',
          type: 'warning'
        })
      }
      if (this.form1.rejectReasonType === '08' && !this.form1.rejectReason) {
        return this.$message({
          message: '拒保原因不能为空',
          type: 'warning'
        })
      }
      const params = {
        guaranteeId: this.rejectId,
        rejectReason: this.form1.rejectReason,
        rejectReasonType: this.form1.rejectReasonType
      }
      reject(params).then((res) => {
        this.jbVisible = false
        if (res.data.success) {
          this.$message({
            message: '拒保成功',
            type: 'success'
          })
          this.getList()
        } else {
          this.$message({
            message: res.data.message,
            type: 'warning'
          })
        }
      })
    },
    //
    reject_view(row){
        this.reject_view_dlg=true
        this.reject_view_form=row
    },
    reject(id) {
        this.jbVisible = true
        this.rejectId = id
        // this.$confirm('此操作将驳回保函申请, 是否继续?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // })
        // .then(() => {
        //   this.jbVisible = true
        //   this.rejectId = id
        // })
        // .catch(() => {
        //   this.$message({
        //     type: 'info',
        //     message: '已取消驳回'
        //   })
        // })
    }
    // console.log('驳回')
  }
}
</script>
<style scoped lang="scss">
.el-table .warning-row {
  /* background: #f35565; */
  border: 1px solid #f35565;
  /* color:#ffffff; */
}

.el-table .success-row {
  background: #ffffff;
}

.upload {
  color: red;
}

.operatorBtn {
  margin: 5px 0 0 10px;
}
::v-deep .el-form-item {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 10px;
}
/* 修改el-table__body-wrapper样式的层级--fixed滚动条无法滚动 */
 ::v-deep .el-table__fixed,
  .el-table__fixed-right {
    pointer-events: none;
    /* 解决因为滚动条无法滚动导致无法勾选和全选的问题 */
    td {
      pointer-events: auto;
    }
    th {
      pointer-events: auto;
    }
  }
</style>
