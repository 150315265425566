<template>
  <div class="app-container">
    <el-form
      ref="params"
      :model="params"
      :inline="true"
    >
      <el-form-item>
        <el-input
          v-model="params.applicant"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="申请人"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="params.telephone"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="申请人电话"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="params.enterpriseName"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="企业名称"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="params.invoiceCategory"
          clearable
          size="mini"
          style="width:150px;"
          placeholder="发票类型"
          @clear="getList"
        >
          <el-option
            v-for="item in invoiceCategoryOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
        </el-form-item>
        <el-form-item>
        <el-select
          v-model="params.status"
          clearable
          size="mini"
          style="width:150px;"
          placeholder="状态"
          @clear="getList"
        >
          <el-option
            v-for="item in invoiceStatusOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
        </el-form-item>
        <el-form-item>
        <el-select
          v-model="params.invoiceSource"
          clearable
          size="mini"
          style="width: 150px"
          placeholder="申请发票来源"
          @clear="getList"
        >
          <el-option
            v-for="item in enterpriseSystemOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="getList"
        >查询</el-button>
        <el-button size="mini" type="primary" icon="el-icon-refresh-left" @click="deleteAddForm">重置</el-button>
         <el-button size="mini" type="primary" icon="el-icon-download" @click="exportFilter">按条件导出</el-button>
        <el-button size="mini" type="primary" icon="el-icon-download" @click="exportAll">全部导出</el-button>
      </el-form-item>
    </el-form>
      <br>
    <el-table
      ref="multipleTable"
      size="mini"
      fit
      :data="tableData"
      :height="tableDataHeight"
      border
      @selection-change="handleSelectionChange"
      v-loading="tableLoading"
    >
      <el-table-column type="selection" width="55" align="center" fixed="" />
      <el-table-column type="index" :index="indexMethod" label="序号" width="50" align="center" fixed=""  />
      <el-table-column prop="type" label="操作" width="120" fixed="" >
        <template slot-scope="scope">
          <el-button style="margin:5px 0 0 0;"  v-if="menuPriPath.indexOf('again-invoice') >= 0"  :disabled="scope.row.status==='00'||scope.row.status==='01'||scope.row.status==='02'" size="mini" type="primary" @click="again_invoice(scope.row)">自动开具</el-button>
           <el-button style="margin:5px 0 0 0;"  v-if="menuPriPath.indexOf('manual-invoice') >= 0 && (scope.row.status==='00'||scope.row.status==='03')" size="mini" type="primary" @click="manual_invoice(scope.row)"> 手动开具 </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="statusName" label="状态" align="center" fixed="" />
      <el-table-column prop="applicant" label="申请人"  />
      <el-table-column prop="telephone" label="申请电话"  />
      <!-- <el-table-column prop="applicantFullAddr" label="收票地址" /> -->
      <el-table-column prop="bankName" label="开户行"  />
      <el-table-column prop="bankCode" label="开户行账户" align="right" />
      <el-table-column prop="enterpriseName" label="企业名称"  />
      <el-table-column prop="enterpriseTelephone" label="企业电话"  />
      <el-table-column prop="enterpriseRegFullAddr" label="企业地址"  />
      <el-table-column prop="mailbox" label="电子邮箱"  />
      <el-table-column prop="taxPayerCode" label="纳税人识别号"  />
      <el-table-column label="发票金额"  prop="invoiceMoney" align="right" />
      <el-table-column label="项目名称"  prop="projectNames" />
      <!-- <el-table-column label="项目编码"  prop="projectCode" /> -->
      <el-table-column prop="totalPrice" label="不含税总金额" align="right" />
      <el-table-column prop="invoiceMoney" label="含税总金额(元)" align="right" />
      <el-table-column prop="totalTax" label="税额" align="right" />
      <el-table-column label="发票类型"  prop="invoiceCategoryName" align="center"/>
       <el-table-column prop="invoiceNo" label="发票号码" align="right" />
       <el-table-column prop="invoiceCode" label="发票代码" align="right" />
       <el-table-column prop="invoiceOrderNumber" label="发票订单号" align="right" />
      
       <el-table-column prop="created" label="开票时间" align="right">
         <template slot-scope="scope">{{$parseTime(scope.row.invoiceKpTime)}}</template>
      </el-table-column>
      <el-table-column prop="created" label="申请时间" align="right">
         <template slot-scope="scope">{{$parseTime(scope.row.created)}}</template>
      </el-table-column>
       <el-table-column prop="appName" label="发票申请来源" align="center" />
      <el-table-column prop="remark" label="备注"  />
    </el-table>
    <!-- 分页 -->
      <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="params.currentPage"
          :limit.sync="params.pageSize"
          @pagination="getList"
        />
         <el-dialog
      title="手动开具发票"
      :visible.sync="manualVisible"
      width="800px"
      append-to-body
      v-if="manualVisible"
    >
      <el-form
        ref="manualForm"
        :model="manualForm"
        :rules="manualFormRule"
        label-width="180px"
        v-loading="manualLoading"
      >
        <el-row>
          <el-col>
            <el-form-item label="发票号码" prop="invoiceNo">
              <el-input v-model="manualForm.invoiceNo" />
            </el-form-item>
          </el-col>
          <el-col>
          <el-form-item label="发票代码" prop="invoiceCode">
              <el-input v-model="manualForm.invoiceCode" />
            </el-form-item>
          </el-col>
          <el-col>
          <el-form-item label="发票订单号" prop="invoiceOrderNumber">
              <el-input v-model="manualForm.invoiceOrderNumber" />
            </el-form-item>
          </el-col>
          <el-col>
          <el-form-item label="开票时间" prop="invoiceKpTime">
              <el-date-picker
                      style="width: 48%"
                      v-model="manualForm.invoiceKpTime"
                      type="date"
                      placeholder="选择日期"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker
                  >
            </el-form-item>
          </el-col>
          <el-col>
          <el-form-item label="备注" prop="remark">
              <el-input v-model="manualForm.remark" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider></el-divider>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitManualKpForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMenuPriByMenuId } from '@/api/menu/index'
import {
  listApplication
} from '@/api/system/application'
import { listInvoice,listInvoiceCategory,listInvoiceStatus,sendAgainKp,manualKp,exportInvoiceList } from '@/api/guarantee/finance'
export default {
  name: 'invoiceList',
  data() {
    return {
            menuPriPath:[],//菜单角色权限
            manualVisible:false,
            manualLoading:false,
      jbVisible:false,
      fileName: '压缩包',
      jbOptions:[],
      invoiceCategoryOptions: [],
      invoiceStatusOptions:[],
      enterpriseSystemOptions:[],
      multipleSelection: [],
      ids: [],
      manualForm:{
        invoiceId:'',
        invoiceNo:'',
        invoiceCode:'',
        invoiceOrderNumber:'',
        invoiceKpTime:'',
        remark:''
      },
      tableLoading:false,
      id: '',
      name: '',
      ziptype: '',
      params: {
        agentId:undefined,
        etpUserId:undefined,
        applicant:undefined,
        telephone:undefined,
        invoiceCategory: undefined,
        invoiceSource:undefined,
        enterpriseName:undefined,
        currentPage: 1,
        pageSize: 10,
        status: ''
      },
      total: 0,
      tableDataHeight:0,
      manualFormRule: {
        invoiceNo: [
          { required: true, message: '发票号码不能为空', trigger: 'blur' }
        ],
        invoiceCode: [
          { required: true, message: '发票代码不能为空', trigger: 'blur' }
        ],
        invoiceOrderNumber: [
          { required: true, message: '发票订单号不能为空', trigger: 'blur' }
        ],
        invoiceKpTime: [
          { required: true, message: '开票时间不能为空', trigger: 'blur' }
        ]
      },
      // 假数据
      tableData: []
    }
  },
  created() {
    this.updateWindowHeight()
  },
  mounted(){
    const path = this.$route.query
    if(path.agentId)this.params.agentId=path.agentId
    if(path.userId)this.params.etpUserId=path.userId
   console.log(this.params)
    this.getRolePriList()
    this.initInvoiceCategory()
    this.initInvoiceStatus()
    this.getEtpSystem()
    this.getList()
  },
  methods: {
    //获取企业系统
    getEtpSystem(){
      this.etpSystemloading = true
      this.enterpriseSystemOptions=[]
      const data={
                currentPage: 1,
        pageSize: 100
      }
      listApplication(data).then((res) => {
        this.etpSystemloading=false
        if (res.data.success) {
          let appList= res.data.result.records
          appList.forEach(element => {
            let app={}
            app.code=element.appid
            app.description=element.name
            this.enterpriseSystemOptions.push(app)
          })
          console.log(this.enterpriseSystemOptions)
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    updateWindowHeight() {
      this.tableDataHeight = window.innerHeight-240
      console.log(this.tableDataHeight)
    },
    getRolePriList() {
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    initInvoiceCategory(){
      listInvoiceCategory().then(res => {
         if(res.data.success){
           this.invoiceCategoryOptions=res.data.result;
         }
      })
    },
    initInvoiceStatus(){
      listInvoiceStatus().then(res => {
         if(res.data.success){
           this.invoiceStatusOptions=res.data.result;
         }
      })
    },
    indexMethod(index){
        return (this.params.currentPage-1)*this.params.pageSize+index+1;
      },

    // 获取列表页请求
    getList() {
      if(this.tableLoading)return;
      this.tableLoading=true;
      listInvoice(this.params).then(res => {
         this.tableLoading=false
         this.tableData = res.data.result.records
        this.total = res.data.result.total
      })
    },
    //按条件导出
    exportFilter() {
      if (!this.params.applicant
           && !this.params.telephone
           && !this.params.status 
           && !this.params.invoiceCategory
           && !this.params.invoiceSource
          ) {
        return this.$msgError('筛选条件不能为空')
      }
      this.tableLoading=true
      exportInvoiceList(this.params).then(res => {
        this.tableLoading=false
        const binaryData = []
        binaryData.push(res.data)
        let url = window.URL.createObjectURL(new Blob(binaryData, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
            const aLink = document.createElement('a')
            aLink.style.display = 'none'
            aLink.href = url
            aLink.setAttribute('download', '发票申请明细导出.xls')
            document.body.appendChild(aLink)
            aLink.click()
            document.body.removeChild(aLink)
            window.URL.revokeObjectURL(url)
      })
    },
    //全部导出
    exportAll() {
      this.tableLoading=true
      const data = {}
      exportInvoiceList(data).then(res => {
        this.tableLoading=false
        const binaryData = []
        binaryData.push(res.data)
        let url = window.URL.createObjectURL(new Blob(binaryData, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
        const aLink = document.createElement('a')
            aLink.style.display = 'none'
            aLink.href = url
            aLink.setAttribute('download', '发票申请明细全部导出.xls')
            document.body.appendChild(aLink)
            aLink.click()
            document.body.removeChild(aLink)
            window.URL.revokeObjectURL(url)
      })
    },
    // 监听pagesize
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.params.pageSize = newSize
      this.getList()
    },
    // 页码值发生切换
    handleCurrentChange(newPage) {
      // console.log(newPage)
      // console.log('将分页保存到session中')
      // const page = newPage
      // sessionStorage.setItem('page', page)
      this.params.currentPage = newPage
      this.getList()
    },
    // 清空
    deleteAddForm(formName) {
      this.params.applicant = undefined
      this.params.telephone=undefined
      this.params.enterpriseName=undefined
      this.params.status=undefined
      this.params.invoiceCategory = undefined
      this.params.invoiceSource=undefined
      this.params.currentPage=1
      this.params.pageSize=10
      this.getList()
    },
    tableRowClassName({ row }) {
      if (row.isDownload === 1) {
        return 'success-row'
      } else {
        return 'warning-row'
      }
    },
    getRowKey(row) {
      // console.log('标识的id')
      // console.log(row.id)
      return row.guid
    },
    // 获取选中的id
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.ids = []
      for (let i = 0; i <= this.multipleSelection.length - 1; i++) {
        this.ids.push(this.multipleSelection[i].guid)
      }
      console.log(val)
    },
    //手动开具弹窗
    manual_invoice(row){
      this.manualVisible=true
      this.manualForm.invoiceCode=''
      this.manualForm.invoiceNo=''
      this.manualForm.invoiceOrderNumber=''
      this.manualForm.invoiceKpTime=''
      this.manualForm.remark=''
      this.manualForm.invoiceId=row.guid
    },
    // 提交手动开票
    submitManualKpForm(){
      this.$refs['manualForm'].validate((valid) => {
        if (valid) {
          this.manualLoading=true
            if (typeof this.manualForm.invoiceKpTime === 'string') {
              this.manualForm.invoiceKpTime = Date.parse(
                this.manualForm.invoiceKpTime
              )
            }
            manualKp(this.manualForm).then(res=>{
                this.manualLoading=false
                this.manualVisible=false
                if (!res.data.success) {
                  this.$msgError(res.data.message)
                } else {
                  this.$msgSuccess('手动开具成功')
                  this.getList()
                }
            })
        }
      })
    },
    //确认退款操作
    again_invoice(row){
       this.$confirm('此操作将发送系统自动开票, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
            console.log('sendKp')
            const data={
              guid:row.guid
            }
            this.tableLoading=true
            sendAgainKp(data).then(res=>{
                console.log(res)
                this.tableLoading=false;
                if (!res.data.success) {
                  this.$msgError(res.data.message)
                } else {
                  this.$msgSuccess('发送开票请求成功')
                  this.getList()
                }
                
            })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消开票'
        })
      })
    },

  }
}
</script>

<style>
 .el-table .warning-row {
    /* background: #f35565; */
    border: 1px solid #f35565;
    /* color:#ffffff; */
  }

  .el-table .success-row {
    background: #ffffff;
  }
  .upload{
    color:red;
  }
</style>